import "../styles/arts.css"; // Keep the styles linked
import { useState, useEffect } from "react"; // Using state to manage photos

const ArtsPage = () => {
    const [photos, setPhotos] = useState([]); // State for storing the photos
    const [isModalOpen, setIsModalOpen] = useState(false); // State for managing modal visibility
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State for the selected photo to display in modal

    // Fetch photos (static example for now, you can replace this with actual data)
    useEffect(() => {
        const fetchedPhotos = [
            { id: 1, imageUrl: "/Arts/IMG001.jpg", smallImageUrl: "/Arts/IMG001-small.jpg" },
            { id: 2, imageUrl: "/Arts/IMG002.jpg", smallImageUrl: "/Arts/IMG002-small.jpg" },
            { id: 3, imageUrl: "/Arts/IMG003.jpg", smallImageUrl: "/Arts/IMG003-small.jpg" },
            { id: 4, imageUrl: "/Arts/IMG004.jpg", smallImageUrl: "/Arts/IMG004-small.jpg" },
            { id: 5, imageUrl: "/Arts/IMG005.jpg", smallImageUrl: "/Arts/IMG005-small.jpg" },
            { id: 6, imageUrl: "/Arts/IMG006.jpg", smallImageUrl: "/Arts/IMG006-small.jpg" },
            { id: 7, imageUrl: "/Arts/IMG007.jpg", smallImageUrl: "/Arts/IMG007-small.jpg" },
            { id: 8, imageUrl: "/Arts/IMG008.jpg", smallImageUrl: "/Arts/IMG008-small.jpg" },
            { id: 9, imageUrl: "/Arts/IMG009.jpg", smallImageUrl: "/Arts/IMG009-small.jpg" },
            { id: 10, imageUrl: "/Arts/IMG010.jpg", smallImageUrl: "/Arts/IMG010-small.jpg" },
            { id: 11, imageUrl: "/Arts/IMG011.jpg", smallImageUrl: "/Arts/IMG011-small.jpg" },
            { id: 12, imageUrl: "/Arts/IMG012.jpg", smallImageUrl: "/Arts/IMG012-small.jpg" },
        ];

        setPhotos(fetchedPhotos); // Set the fetched photos in state
    }, []); // Empty dependency array ensures this runs only once on mount

    // Function to open modal with selected photo
    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPhoto(null);
    };

    // Function to handle key events for accessibility
    const handleKeyUp = (e, photo) => {
        if (e.key === "Enter" || e.key === " ") {
            openModal(photo);
        }
    };

    return (
        <div id="photo-page-container">
            <div id="photo-container">
                {photos.length > 0 ? (
                    photos.map((photo) => (
                        <div
                            key={photo.id}
                            className="photo-card"
                            onClick={() => openModal(photo)}
                            onKeyUp={(e) => handleKeyUp(e, photo)} // Add keyup for accessibility
                            tabIndex="0" // Ensure the element is focusable
                        >
                            <img 
                                src={photo.smallImageUrl} 
                                alt={`photo-${photo.id}`} 
                                className="photo-image" 
                                loading="lazy"
                                srcSet={`${photo.smallImageUrl} 600w, ${photo.imageUrl} 1200w`}
                                sizes="(max-width: 600px) 100vw, 50vw"
                            />
                        </div>
                    ))
                ) : (
                    <p>Loading photos...</p>
                )}
            </div>

            {/* Modal for viewing the enlarged photo */}
            {isModalOpen && selectedPhoto && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img 
                            src={selectedPhoto.imageUrl} 
                            alt={`photo-${selectedPhoto.id}`} 
                            className="modal-image"
                        />
                        {/* Close button with type attribute */}
                        <button 
                            className="close-btn" 
                            onClick={closeModal} 
                            type="button" // Explicit type attribute for button
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ArtsPage;
