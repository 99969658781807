import "../styles/photography.css"; // Keeps the styles linked
import { useState, useEffect } from "react"; // Using state to manage photos

const PhotographyPage = () => {
    const [photos, setPhotos] = useState([]); // State for storing the photos
    const [isModalOpen, setIsModalOpen] = useState(false); // State for managing modal visibility
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State for the selected photo to display in modal

    // Fetch photos (static example for now, you can replace this with actual data)
    useEffect(() => {
        const fetchedPhotos = [
            { id: 1, imageUrl: "/Photography/IMG001.jpg" }, 
            { id: 2, imageUrl: "/Photography/IMG002.jpg" }, 
            { id: 3, imageUrl: "/Photography/IMG003.jpg" }, 
            { id: 4, imageUrl: "/Photography/IMG004.jpg" }, 
            { id: 5, imageUrl: "/Photography/IMG005.jpg" }, 
            { id: 6, imageUrl: "/Photography/IMG006.jpg" }, 
            { id: 7, imageUrl: "/Photography/IMG007.jpg" }, 
            { id: 8, imageUrl: "/Photography/IMG008.jpg" },
        ];

        setPhotos(fetchedPhotos); // Set the fetched photos in state
    }, []);

    // Function to open modal with selected photo
    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPhoto(null);
    };

    // Function to handle key events for accessibility
    const handleKeyDown = (e, photo) => {
        if (e.key === "Enter" || e.key === " ") { // Allow Enter or Space to open the modal
            openModal(photo);
        }
    };

    return (
        <div id="photo-page-container">
            <div id="photo-container">
                {photos.length > 0 ? (
                    photos.map((photo) => (
                        <div 
                            key={photo.id} 
                            className="photo-card" 
                            onClick={() => openModal(photo)} 
                            onKeyDown={(e) => handleKeyDown(e, photo)} // Allow keyboard interaction
                            role="button" 
                            tabIndex={0} // Make the div focusable for keyboard users
                        >
                            <img 
                                src={photo.imageUrl} 
                                alt={`photo-${photo.id}`} 
                                className="photo-image" 
                                loading="lazy" // Lazy load images for better performance
                            />
                        </div>
                    ))
                ) : (
                    <p>Loading photos...</p>
                )}
            </div>

            {/* Modal for viewing the enlarged photo */}
            {isModalOpen && selectedPhoto && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img 
                            src={selectedPhoto.imageUrl} 
                            alt={`photo-${selectedPhoto.id}`} 
                            className="modal-image"
                        />
                        {/* Close button with type attribute */}
                        <button 
                            className="close-btn" 
                            onClick={closeModal} 
                            type="button" // Explicit type for button
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhotographyPage;
